
import { Component, Prop, Watch } from "vue-property-decorator";
import {AssociationResource, MediaResource} from "@/resources"
import MediaThumbnailImage from '@/components/media/MediaThumbnailImage.vue'
import { BaseMediaComponent } from "../base/BaseMixins"
import MediaUploadDialog from "./MediaUploadDialog.vue";
import BaseDialog from "../base/BaseDialog.vue";
const MediaDetailsDialog = () => import('@/components/media/MediaDetailsDialog.vue')

@Component({components : {BaseDialog, MediaUploadDialog, MediaDetailsDialog, MediaThumbnailImage}})
export default class MediaImageStrip extends BaseMediaComponent {
  @Prop() readonly managementAssociationResource!: AssociationResource<MediaResource[]>
  @Prop({ default: 5 }) readonly numberToShow!: number
  @Prop({ default: false }) readonly showAll!: boolean
  @Prop({ default: 60}) readonly width!: number
  @Prop({ default: 1}) readonly marginFactor!: number
  @Prop({default: false}) readonly disableAdd !: boolean
  @Prop({default: false}) readonly disableLabel !: boolean
  @Prop({default: true}) readonly showDetailsDialog !: boolean
  @Prop({default: false}) readonly hideIfNone !: boolean

  @Prop() readonly uploadUrl!: string

  @Prop() readonly value !: number
   
  // for testing only
  testSize : number = 0
  selectedIdx : number | null = null
  detailsDialog : boolean = false
  uploadDialog : boolean = false

  get numText() {
    if (this.numItems) {
      return this.numItems + " item" + (this.numItems > 1 ? 's' : '')
    }
    return "no items" + (this.disableAdd ? '' : ", click + to add")
  }

  get showAddBlock() {
    return this.numItems < this.numberToShow
  }

  get items() {
    return this.rdata.slice(0,this.numItems)
  }

  get numItems() {
    return this.testSize ? this.testSize : this.size
  }

  get showingArrows() {
    // -1 for the add button
    return (this.sizeOfArrows + (this.sizeOfBlock * this.numItems)) > this.stripWidth
  }

  get sizeOfArrows() {
    return 48
  }

  get sizeOfBlock() {
    return (this.width + (this.marginFactor * 4 * 2))
  }

  get stripWidth() {
    // (width of prev/next button * 2) + ((image width + (margin factor * 4 * 2)) * numberToShow) 
    return this.sizeOfArrows + (this.sizeOfBlock * this.numberToShow)
  }

  get selectedMedia() {
    return this.selectedIdx != null && this.selectedIdx >= 0 ? new MediaResource(this.rdata[this.selectedIdx].id) : undefined
  }

  @Watch("value", {immediate: true})
  valueChanged() {
    if (this.value !== undefined && this.value >= 0) {
      this.selectedIdx = this.value
    }
  }

  selectionChanged(idx : number) {
    this.selectedIdx = idx
     this.$emit('selected', this.selectedIdx)
     if (this.showDetailsDialog) {
      this.detailsDialog = true
     }
  }
}
