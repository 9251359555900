  
import { Component } from "vue-property-decorator"
import {InstantUpdateFieldMixin} from "@/components/forms/FormsMixin"

@Component({ components: {}, inheritAttrs: false })
export default class InstantUpdateTextArea extends InstantUpdateFieldMixin {
  getHint() {
    return "Press [Shift + Return] to update."
  }

  get halPropsObject() {
    return this.halProps(this.propName, this.$attrs);
  }
}
