
import {Component, Prop, Watch} from 'vue-property-decorator' 
import ValueBasedFormsMixin from "@/components/forms/FormsMixin"
import BaseHtmlEditor from "@/components/base/BaseHtmlEditor.vue"
import AddressForm from '../address/AddressForm.vue'
import { cloneDeep, set, tap } from 'lodash-es'
import ContactTypeInfoCard from "@/components/contacts/ContactTypeInfoCard.vue"
import { PropertyResource, SettingsResource } from '@/resources'

@Component({components: {AddressForm, ContactTypeInfoCard, BaseHtmlEditor}})
export default class ContactForm extends ValueBasedFormsMixin { 
  @Prop() propertyAddress !: any
  @Prop() excludeTypes !: string[]

  showAddress : boolean = false
  showNotes : boolean = true
  usePropertyAddress : boolean = true

  getHalFormName() : string { return "contact" }

  get firstnameProps() {
    return this.halProps('firstname', {required: this.namesMissing})
  }
  get lastnameProps() {
    return this.halProps('lastname', {required: this.namesMissing})
  }
  get nicknameProps() {
    return this.halProps('nickname', {required: this.namesMissing})
  }

  /**
   * Name validation is a bit complex, if not a business
   * must have at least first, last or nick name, if business
   * must have business name.
   * TODO a bit clumsy, need form validation not tied to one field
   */
  get namesMissing() {
    const f = this.value.firstname ? this.value.firstname : ""
    const l = this.value.lastname ? this.value.lastname : ""
    const n = this.value.nickname ? this.value.nickname : ""
    const test = "".concat(f , l, n).trim()
    return test.length == 0
  }

  /**
   * New if no id or a special flag used in situations where we
   * aren't editing an existing real contact, but a temporarily
   * created one saved on the client side but not in the server
   * (e.g. new Client context).
   */
  get isNew() {
    return !this.value.id && !this.value.editedOnce
  }

  @Watch("usePropertyAddress")
  usePropertyAddressChanged() {
    if (!this.usePropertyAddress) {
      // reset with default address
      var tempContact = {...SettingsResource.defaultObject("contact")}
      this.doUpdate('address', tempContact.address)
    }
    else {
      // else reset with property address
      this.doUpdate('address', this.propertyAddress)
    }
  }

  get propertyAddressString() {
    return this.propUtil.getPropertyAddress({address : this.propertyAddress})
  }

  get filteredTypes() {
    var options = this.getHalOptions(this.getHalFormName(), 'type')
    if (this.excludeTypes) {
      options = options.filter((o : any) => !this.excludeTypes.includes(o.value))
    }
    return options
  }

  get propUtil() {
    return PropertyResource
  }

  hasType(type : string) {
    return !this.excludeTypes || !this.excludeTypes.includes(type)
  }
  
  get isBusiness() {
    var isBiz = this.value.type == "BUSINESS"
    return isBiz
  }

  @Watch("value.type", {deep: true})
  typeChanged(newVal : any, oldVal: any) {
    // clear out name fields if biz
    if (newVal != oldVal) {
      if (this.isBusiness) {
        var clonedAndMerged = cloneDeep(this.getValue())
        clonedAndMerged = tap(clonedAndMerged, v => set(v, 'firstname', null))
        clonedAndMerged = tap(clonedAndMerged, v => set(v, 'lastname', null))
        clonedAndMerged = tap(clonedAndMerged, v => set(v, 'title', null))
        clonedAndMerged = tap(clonedAndMerged, v => set(v, 'nickname', null))
        this.$emit('input', clonedAndMerged)
      }
      // else ensure biz name not set
      else {
        this.doUpdate('name', null)
      }
    }
   
  }

  get typeNames() {
    return this.getHalOptions(this.getHalFormName(), 'type')
  }

  getTypeName(item : any) {
    var found = this.typeNames.find( i => i.value == item.value)
    return found.text
  }
  
}
