
import {Vue, Component, Prop} from 'vue-property-decorator'
const { DateTime } = require("luxon")
//  <v-chip label small filter filter-icon="mdi-menu-down" :input-value="true" class="font-weight-bold" :text-color="status.textColor" :color="status.color" v-bind="$attrs" v-on="$listeners" v-html="status.text"></v-chip>

@Component({components: {}})
export default class JobStatusChip extends Vue {
  @Prop({ required : true }) readonly job!: any

  statuses = {
    overdue     : {text: "Overdue", color : "error", textColor: "white"},
    upcoming    : {text: "Upcoming", color : "warning", textColor: "black"},
    unscheduled : {text: "Unscheduled", color : "warning", textColor: "black"},
    scheduled   : {text: "Scheduled", color : "success", textColor: "white"},
    cancelled   : {text: "Cancelled", color : "error", textColor: "white"},
    closed      : {text: "Completed", color : "black", textColor: "white"}
  }

  get status() {
    if (this.job.status === "CLOSED_COMPLETED") {
      return this.statuses.closed
    }
    else if (this.job.status === "CLOSED_CANCELLED") {
      return this.statuses.cancelled
    }
    else if (this.job.status === "OPEN_SCHEDULED") {
      return this.statuses.scheduled
    }

    if (this.job.startDate) {
      var jobStartDate = DateTime.fromISO(this.job.startDate)
      if (jobStartDate < DateTime.now().endOf('day')) {
        return this.statuses.overdue
      }
      return this.statuses.upcoming
    }

    return this.statuses.unscheduled
    
  }

}
