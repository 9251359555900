
import {Component, Prop, Watch} from 'vue-property-decorator'
import { Loader } from "@googlemaps/js-api-loader"
import { BasePropertyComponent } from '../base/BaseMixins'

@Component({components: {}})
export default class PropertyMap extends BasePropertyComponent {
    @Prop({ default: true }) readonly mapTypeControl!: boolean
    @Prop({ default: true }) readonly scaleControl!: boolean
    @Prop({ default: true }) readonly zoomControl!: boolean
    @Prop({ default: true }) readonly panControl!: boolean
    @Prop({ default: false}) readonly streetViewControl!: boolean
    @Prop({ default: true }) readonly fullscreenControl!: boolean

    map !: google.maps.Map 
    marker !: google.maps.Marker
    apiKey : any = process.env.VUE_APP_GOOGLE_API_KEY

    $refs!: {
      map: HTMLElement
    }

    beforeDestroy() {
      if (this.map) {
        google.maps.event.clearInstanceListeners(this.map);
      }
    }

    get mapElement() {
      return this.$refs.map
    }

    setMapBounds(viewportBounds : any) {
      // use viewport if we have it
      if (viewportBounds) {
        var points = viewportBounds.split('|')
        if (points.length === 4) {
          var boundsA = new google.maps.LatLng(points[0],points[1])
          var boundsB = new google.maps.LatLng(points[2],points[3])
          var bounds = new google.maps.LatLngBounds()
          bounds.extend(boundsA)
          bounds.extend(boundsB)
          this.map.fitBounds(bounds)
        }
      }
    }

    mounted() {
        const loader = new Loader({
          apiKey: this.apiKey,
          version: "weekly",
        });
        this.$emit("updating")
        loader.load().then(() => {

          /* global google */
          // create bounds
          var center
           // if one property, set bounds based on that.
          if (this.size == 1 && this.rdata) {
            center = new google.maps.LatLng(this.rdata.geo.latitude,this.rdata.geo.longitude)
          }
          else {
            // TODO get from default service area/property if one
            const geo : any = {latitude : 49.1450161, longitude: -123.8566661}
            center = new google.maps.LatLng(geo.latitude,geo.longitude)
          }

          var mapOptions = {
            center: center,
            zoom: 12,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            panControl: this.panControl,
            mapTypeControl: this.mapTypeControl,
            fullscreenControl : this.fullscreenControl,
            panControlOptions: {
                  position: google.maps.ControlPosition.RIGHT_CENTER
            },
            zoomControl: this.zoomControl,
            zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER
            },
            scaleControl: this.scaleControl,
            streetViewControl: this.streetViewControl,
            streetViewControlOptions: {
              position: google.maps.ControlPosition.RIGHT_CENTER
            }
          }
              
          // create map & marker
          this.map = new google.maps.Map(this.mapElement, mapOptions)
          this.marker = new google.maps.Marker({position: center, map: this.map});  // eslint-disable-line no-unused-vars

          this.map.addListener("bounds_changed", () => {
            this.$emit("updating")
          });
          this.map.addListener("idle", () => {
            this.$emit("updated")
          });

          // if one property, set bounds based on that.
          if (this.size == 1 && this.resource) {
            this.setMapBounds(this.rdata.geo.viewportBounds);
          }
        });
    }

    @Watch("rdata")
    geoChanged(newGeo : any, oldGeo : any) {
      if (oldGeo && this.map) {
        const center = new google.maps.LatLng(newGeo.geo.latitude,newGeo.geo.longitude)
        this.marker.setPosition(center);
        this.map.setCenter(center);
        this.setMapBounds(newGeo.geo.viewportBounds)
      }
    }
}
