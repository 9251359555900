
import {Component, Prop} from 'vue-property-decorator'
import BasePanel from '@/components/base/BasePanel.vue'
import BaseResourceAssociationDataTable from "@/components/base/BaseResourceAssociationDataTable.vue"
import BaseOptionsMenu from '../base/BaseOptionsMenu.vue'
import { ClientResource, MaintenanceJobResource, PropertyResource} from '@/resources'
import JobStatusChip from './JobStatusChip.vue'
import { MODE_ADD_EDIT } from './JobFormDialog.vue'
import JobFormDialog from './JobFormDialog.vue'
import { orderBy } from 'lodash-es'
import { BaseMaintenanceJobComponent, RequiredProjections } from '../base/BaseMixins'
const { DateTime } = require("luxon")


@RequiredProjections("maintenanceJobSummary")
@Component({components: {JobStatusChip, JobFormDialog, BasePanel, BaseOptionsMenu, BaseResourceAssociationDataTable}})
export default class JobsTableClientPaged extends BaseMaintenanceJobComponent {

  @Prop({required: false}) associationParams !: any
  @Prop({required: false, default: true}) dense !: boolean
  @Prop({default: false}) hidePropertyAndClient !: boolean

  jobDialog : boolean = false
  jobFormMode = MODE_ADD_EDIT
  jobToEdit : MaintenanceJobResource | null = null
  jobToEditProperty : PropertyResource | null = null

  openJobHeadersSortable = [
      { text: 'Status', value: 'status', sortable: false},
      { text: 'Details', value: 'details', width: "100%", sortable: false},
      { text: 'Start date', value: 'startDate', sortable: true},
      { text: '', value: 'actions'}
  ]

  closedJobHeaders = [
      { text: 'Status', value: 'status', sortable : false},
      { text: 'Details', value: 'details', width: "100%", sortable: false},
      { text: 'Closed date', value: 'endDate'}
  ]

  tab : any = null

  get dateMed() {
    return DateTime.DATE_MED
  }

  clientName(client : any) {
    return ClientResource.getClientName(client)
  }
  propertyName(property : any) {
    return PropertyResource.getPropertyAddress(property)
  }

  sortDirections(sortDesc : boolean[]) {
    return sortDesc.map(s => s ? "desc" : "asc")
  }

  sortByEndDate(items: any[], sortBy: string[], sortDesc: boolean[]) {
    var sortedItems = orderBy(items, [(value : any) => DateTime.fromISO(value.endDate)], this.sortDirections(sortDesc))
    return sortedItems
  }

  sortByStartDate(items: any[], sortBy: string[], sortDesc: boolean[]) {
    var sortedItems = orderBy(items, [(value : any) => DateTime.fromISO(value.startDate)], this.sortDirections(sortDesc))
    return sortedItems
  }

  gotoJob(job : any) {
    this.$router.push({ name: 'job', params: {jid:job.id, pid : job.property.id, pmsId: job.propertyMaintenanceSystem.id}})
  }

  async editJob(job : any) {
    
    this.jobFormMode = MODE_ADD_EDIT
    this.jobToEdit = new MaintenanceJobResource(job.id)
    this.jobToEditProperty = new PropertyResource(job.property.id)
    await this.jobToEdit.get()
    await this.jobToEditProperty.get()
    
    this.jobDialog = true
  }

  get jobs() {
    return this.rdata
  }

  get openJobs() {
    return this.jobs.filter((j:any) => j.status.startsWith("OPEN"))
  }

  get closedJobs() {
    return this.jobs.filter((j:any) => j.status.startsWith("CLOSED"))
  }

}
