
import {Component, Mixins, Prop} from 'vue-property-decorator'
import BaseResourceFormDialog from "@/components/base/BaseResourceFormDialog.vue"
import JobForm from "@/components/jobs/JobForm.vue"
import {ResourceFormDialogMixin} from "@/components/forms/FormsMixin"
import {SettingsResource, PropertyMaintenanceSystemsResource, PropertyResource, ServiceLoopResource, MaintenanceJobResource, JobTemplateResource, AssociationResource, MaintenanceSystemsResource} from "@/resources"
import ClientName from '../clients/ClientName.vue'
import PropertyAddress from '../properties/PropertyAddress.vue'
import PropertyMaintenanceSystemName from '../properties/PropertyMaintenanceSystemName.vue'
import RecurrenceControl from '../serviceLoops/RecurrenceControl.vue'
import MaintenanceSystemName from '../maintenanceSystems/MaintenanceSystemName.vue'
import BaseResourceProperty from '../base/BaseResourceProperty.vue'
import JobNextDates from './JobNextDates.vue'
import { DateTime } from 'luxon'
import { FORM_MODE_NEW, FORM_MODE_EDIT, FORM_MODE_NEW_FROM_LOOP, FORM_MODE_NEW_FROM_JOB } from '@/components/jobs/JobForm.vue'


export const MODE_ADD_EDIT = 1
export const MODE_ADD_FROM_LOOP = 2
export const MODE_ADD_FROM_JOB = 3
export const MODE_COPY_FROM_JOB = 4

@Component({components: {BaseResourceFormDialog, JobNextDates, BaseResourceProperty, MaintenanceSystemName, RecurrenceControl, PropertyMaintenanceSystemName, PropertyAddress, ClientName, JobForm}})
export default class JobFormDialog extends Mixins(ResourceFormDialogMixin)  { 

  @Prop() resource !: MaintenanceJobResource
  @Prop({required: true}) mode !: 1 | 2 | 3 | 4
  @Prop({required: true}) propertyResource !: PropertyResource
  @Prop() readonly propertyMaintenanceSystem!: PropertyMaintenanceSystemsResource
  @Prop() readonly initServiceLoop !: ServiceLoopResource
  @Prop() readonly initMaintenanceJob !: MaintenanceJobResource

  /**
   * Resource to use for fetching job templates.
   */
  jobTemplatesAssociation : AssociationResource<JobTemplateResource[]> | null = null

  /**
   * Service loop used (init from initServiceLoop) with the correct projection
   */
  serviceLoopForInit : ServiceLoopResource | null = null

  /**
   * Maintenance job used (init from initMaintenanceJob) with the correct projection
   */
  maintenanceJobForInit : MaintenanceJobResource | null = null

  formMode : 1 | 2 | 3 | 4 = FORM_MODE_NEW

  hasAppointments : boolean = false

  /**
   * Only used in FORM_MODE_EDIT, if previous job was a simple one, 
   * else we don't want to distrub any worfklows that may be 
   * attached
   */
  disableJobType : boolean = false

  /**
   * For initing by job only, sets next job date
   */
  nextStartDate : any = null
  allReady : boolean = false
  rdata : any = null

  async asyncInit() {

    if (!this.propertyResource) {
      throw new Error("propertyResource required.")
    }

    // if editing fetch
    if (this.resource) {
      this.rdata = (await new MaintenanceJobResource(this.resource.uriFull + "?projection=maintenanceJobSummary").get(false)).data
      
      // check for appointments
      var appts = await this.resource.appointments.getAssociation()
      this.hasAppointments = appts.length > 0

      // if existing job already has a template, block changing 
      // as we don't want to disturb any existing workflows that
      // are operational (have a process id)
      if (this.rdata.jobTemplate) {
        this.disableJobType = true
      }

      // edit mode for form
      this.formMode = FORM_MODE_EDIT
    }

    // if editing ensure any changed job templates use same PMS
    if (this.resource) {
      var pms = await this.resource.propertyMaintenanceSystem.getAssociation()
      let mr = await pms.maintenanceSystem.getAssociation()
      this.jobTemplatesAssociation = mr.jobTemplates // NOT ROLLED UP
    }
    // if given a PMS, use the rolled up templates from the MS 
    else if (this.propertyMaintenanceSystem) {

      // ensure what we are given isn't the root
      let testPms = (await new PropertyMaintenanceSystemsResource(this.propertyMaintenanceSystem.uriFull + "?projection=propertyMaintenanceSystemSummary").get()).data
      if (testPms.isRoot) {
        throw new Error("propertyMaintenanceSystem property can not be root.")
      }

      let mr = await this.propertyMaintenanceSystem.maintenanceSystem.getAssociation()
      this.jobTemplatesAssociation = mr.jobTemplatesRolledUp
    }
    // else use the rolled up from the property root PMS
    else {
      let allPms = await this.propertyResource.maintenanceSystems.getAssociation({projection:"propertyMaintenanceSystemSummary"})
      let rootPms = allPms.find(pms => pms.data().isRoot)
      if (!rootPms) {throw new Error("Could not find root PMS for property : " + this.propertyResource.uriFull)}
      let mr = await rootPms.maintenanceSystem.getAssociation()
      this.jobTemplatesAssociation = mr.jobTemplatesRolledUp            
    }

    // if service loop, ensure it is fetched with the proper projection
    if (this.initServiceLoop) { 
      this.serviceLoopForInit = new ServiceLoopResource(this.initServiceLoop.uriFull + "?projection=serviceLoopDetail")
      await this.serviceLoopForInit.get(); 

      // edit mode for form
      this.formMode = FORM_MODE_NEW_FROM_LOOP
    }

    // if maintenance job, fetch the right projection
    if (this.initMaintenanceJob) {
      this.maintenanceJobForInit = new MaintenanceJobResource(this.initMaintenanceJob.uriFull + "?projection=maintenanceJobSummary")
      let mjData = await this.maintenanceJobForInit.get(); 

      // edit mode for form
      this.formMode = FORM_MODE_NEW_FROM_JOB

      // fetch the next date to use when initing the job
      let recurrence = mjData.data.recurrence
      if (recurrence) {
        // ensure GMT
        let startDate = DateTime.fromISO(mjData.data.startDate).setZone('utc').toFormat("yyyy-MM-dd")
        let dates = await MaintenanceJobResource.nextdate(recurrence, startDate, "1")
        if (dates.data && dates.data.length == 1) {
          this.nextStartDate = dates.data[0]
        }
      }
    }
  }

  get collectionResource() {
    return new MaintenanceJobResource()
  }

  created() {
    this.asyncInit().then(() => {
      this.allReady = true
    })
  }


  get dialogTitle() {
    return this.collectionResource.label.toLowerCase()
  }

  get titlePrefix() {
    return this.mode == 4 ? "Copy " : undefined
  }


  /**
   * New model for ADD/EDIT mode
   */
  newModel_addEdit() {
    // if editing, return data
    if (this.rdata) {
      if (this.propertyMaintenanceSystem) { throw new Error("No propertyMaintenanceSystem needed when editing.")}

      var job = {...this.rdata,
        maintenanceSystemName: this.rdata.maintenanceSystemName,
        jobType : this.rdata.jobTemplate ? this.rdata.jobTemplate.name : JobTemplateResource.simpleJobLabel
      }

      // ensure recurrence is set for reactivity
      if (!job.recurrence) {
        job.recurrence = null
      }

      // data is from projection, ensure the relationships use the URLS
      job.propertyMaintenanceSystem = new PropertyMaintenanceSystemsResource(this.rdata.propertyMaintenanceSystem.id).uriFull
      job.property = this.propertyResource.uriFull
      job.jobTemplate = this.rdata.jobTemplate ? new JobTemplateResource(this.rdata.jobTemplate.id).uriFull : null

      return job
    }

    return {
      ...SettingsResource.defaultObject("maintenanceJob"),
      jobType : JobTemplateResource.simpleJobLabel,
      maintenanceSystemName : null,
      property: this.propertyResource.uriFull,
      propertyMaintenanceSystem : this.propertyMaintenanceSystem ? this.propertyMaintenanceSystem.uriFull : null,
      newSystemMid : null // when a JT is for a PMS that doesn't exist
    }
  }

  /**
   * New model for MODE_ADD_FROM_LOOP mode.
   */
   newModel_fromLoop() {
      if (!this.serviceLoopForInit) { throw new Error("Missing service loop initialization.")}
      if (this.propertyMaintenanceSystem) { throw new Error("No propertyMaintenanceSystem needed when initing from loop.")}
      if (this.resource) { throw new Error("Can not use service loop init with existing job.")}

      // basic init
      var job = this.newModel_addEdit();

      // if the loop has a job template, prefill that data
      var slData = this.serviceLoopForInit.data();
      job.maintenanceSystemName = slData.maintenanceSystemName
      job.propertyMaintenanceSystem = new PropertyMaintenanceSystemsResource(slData.propertyMaintenanceSystem.id).uriFull
      
      if (slData.jobTemplate) {
        job.name = slData.jobTemplate.name
        job.description = slData.jobTemplate.description
        job.estimatedDuration = slData.jobTemplate.duration
        job.jobTemplate = new JobTemplateResource(slData.jobTemplate.id).uriFull

        // for header display
        job.jobType = slData.jobTemplate.name 
     } else {
        job.name = slData.simpleJobName ? slData.simpleJobName : JobTemplateResource.simpleJobLabel
        job.jobType = JobTemplateResource.simpleJobLabel
      }
      job.recurrence = slData.loopRecurrence

      return job
  }

  /**
   * New model for MODE_ADD_FROM_JOB mode.
   */
   newModel_fromJob(copyStart : boolean) {
      if (!this.maintenanceJobForInit) { throw new Error("Missing maintenance job initialization.")}
      if (this.propertyMaintenanceSystem) { throw new Error("No propertyMaintenanceSystem needed when initing from job.")}
      if (this.resource) { throw new Error("Can not use maintenance job init with existing job.")}

      // basic init
      var job = this.newModel_addEdit();

      var mjData = this.maintenanceJobForInit.data(); // TODO safe to assume ?
      job.name = mjData.name
      job.description = mjData.description
      job.estimatedDuration = mjData.estimatedDuration
      job.recurrence = mjData.recurrence  
      job.startDate = copyStart ? mjData.startDate : this.nextStartDate

      job.maintenanceSystemName = mjData.maintenanceSystemName
      job.propertyMaintenanceSystem = new PropertyMaintenanceSystemsResource(mjData.propertyMaintenanceSystem.id).uriFull
      job.jobType = mjData.jobTemplate ? mjData.jobTemplate.name : JobTemplateResource.simpleJobLabel

      return job
  }

  newModelFunction() : any {

    // adding/editing new
    if (this.mode == MODE_ADD_EDIT) {
      var fm = this.newModel_addEdit();
      if (!fm.propertyMaintenanceSystem) { throw new Error("PropertyMaintenanceSystem required when creating new job.")}
      return fm
    }
    else if (this.mode == MODE_ADD_FROM_LOOP) {
      return this.newModel_fromLoop();
    }
    else if (this.mode == MODE_ADD_FROM_JOB) {
      return this.newModel_fromJob(false);
    }
    else if (this.mode == MODE_COPY_FROM_JOB) {
      return this.newModel_fromJob(true);
    }
    else {
      throw new Error("Unknown mode: " + this.mode)
    }
  }


  async preSaveCallback(formModel : any) {
    if (!formModel.propertyMaintenanceSystem && formModel.newSystemMid) {       
      // create new PMS and set in form model
      try {
        let pmsUri = (await new PropertyMaintenanceSystemsResource().post({
          property : this.propertyResource.uriFull,
          maintenanceSystem : new MaintenanceSystemsResource(formModel.newSystemMid).uriFull
        })).uri

        formModel.propertyMaintenanceSystem = pmsUri
      }
      catch (e: any) {
          console.error("Could not create new maintenannce system (pid: " + this.propertyResource.data().id + ", mid: " + formModel.newSystemMid + "): " + e);
          throw e
      }
    }
    return Promise.resolve()
  }

}
