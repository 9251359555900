import { render, staticRenderFns } from "./JobStatusChip.vue?vue&type=template&id=3bdb76fa&scoped=true"
import script from "./JobStatusChip.vue?vue&type=script&lang=ts"
export * from "./JobStatusChip.vue?vue&type=script&lang=ts"
import style0 from "./JobStatusChip.vue?vue&type=style&index=0&id=3bdb76fa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bdb76fa",
  null
  
)

export default component.exports