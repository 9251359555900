
import {Component, Prop, Vue} from 'vue-property-decorator'
import { MaintenanceJobResource } from '@/resources'
import AsyncComputed from 'vue-async-computed-decorator'
import { DateTime } from 'luxon'

@Component({components: {}})
export default class JobNextDates extends Vue {
  @Prop({default: 5}) readonly count !: number
  @Prop({required: true}) readonly startDate !: string
  @Prop({required: true}) readonly rrule !: string

  @AsyncComputed()
  async nextdates() {
    if (!!this.rrule && !!this.startDate && !!this.count) {
      // ensure GMT to server
      return MaintenanceJobResource.nextdate(this.rrule, DateTime.fromISO(this.startDate).setZone('utc').toFormat("yyyy-MM-dd"), this.count)
    }
    return {data : []}
  }

  dateFormat(d : string) {
    return d ? DateTime.fromISO(d).toLocaleString(DateTime.DATE_HUGE) : ""
  }
}
