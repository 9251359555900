
import {Component, Prop} from 'vue-property-decorator'
import { BaseEntityResourceComponent } from '@/components/base/BaseMixins'

@Component({components: {}})
export default class BaseResourceProperty extends BaseEntityResourceComponent {
  @Prop({default: "span"}) readonly tag !: string
  @Prop({required: true}) readonly property !: string
  @Prop({default: ""}) readonly emptyValue !: any
}
