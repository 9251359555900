
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Editor, EditorContent } from '@tiptap/vue-2'
import { Node } from '@tiptap/core'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import colors from 'vuetify/lib/util/colors'
import Placeholder from '@tiptap/extension-placeholder'
import CharacterCount from '@tiptap/extension-character-count'

/**
 * Converts deprecated font tags with a color attribute to a styled span.
 * Much of the old DB have font tags.
 */
const FontTagConverter = Node.create({
    content: 'inline*',
    group: 'block',
    parseHTML() {
        return [
        {
            tag: 'font',
            // @ts-ignore
            getAttrs: element => element.hasAttribute('color'),
        },
        ]
    },
    addAttributes() {
        return {
            color: {
                    default: null,
                    // Take the attribute values
                    renderHTML: attributes => {
                        // … and return an object with HTML attributes.
                        return {
                            style: `color: ${attributes.color}`,
                        }
                    },
            },
        }
    },
    renderHTML({ HTMLAttributes }) {
        return ['span', HTMLAttributes, 0]
    },
})

// TODO convert to a proper vuetify input type with hints/palceholders, messages and validation
let uniqID = 0
@Component({components : {EditorContent}})
export default class BaseHtmlEditor extends Vue {
    @Prop({ default: ""}) readonly value!: string | null
    @Prop({}) readonly cssMaxHeight !: string | number
    @Prop({default : "200px"}) readonly cssMinHeight !: string | number
    @Prop({default : "1em"}) readonly cssFontSize !: string | number
    @Prop({default: false}) readonly hideToolbar !: boolean

    editor : Editor | null = null
    isDirty : boolean = false
    colors = [
        {text: "one", color: colors.shades.black},
        {text: "amber", color: colors.amber.darken3},
        {text: "red", color: colors.red.darken2},
        {text: "green", color: colors.green.darken2},
    ]

    @Watch('value')
    valueChanged(newValue : any) {
        const isSame = this.editor?.getHTML() === newValue
        if (isSame) {
            return
        }
       
        this.editor?.commands.setContent(newValue, false)
    }

    get componentId() {
        return 'ProseMirror-' + uniqID++
    }

    mounted() {
        this.editor = new Editor({
            content: this.value,
            autofocus: !!this.$attrs.autofocus,
            extensions: [
                StarterKit,
                Underline,
                TextStyle,
                Color,
                FontTagConverter,
                Placeholder.configure({
                    placeholder: this.$attrs.label,
                }),
                CharacterCount.configure({
                    limit: null,
                }),
            ],
            onUpdate: () => {
                // HTML
                this.$emit('input', this.editor?.getHTML())

                // JSON
                // this.$emit('input', this.editor.getJSON())

                this.isDirty = true
            },
        })
    }

    beforeDestroy() {
        this.editor?.destroy()
    }
}
