
import { Component, Prop } from "vue-property-decorator";
import {ClientResource, PropertyResource} from "@/resources"
import ClientPropertyDetachDialog from "@/components/clients/ClientPropertyDetachDialog.vue"
import PropertyAddDialog from '@/components/properties/PropertyAddDialog.vue'
import BaseOptionsMenu from '@/components/base/BaseOptionsMenu.vue'
import {DateTime} from "luxon"
import { BasePropertyComponent } from "../base/BaseMixins";
import BasePanel from "../base/BasePanel.vue";
import PropertyAttachDialog from "../properties/PropertyAttachDialog.vue";

@Component({components : {PropertyAttachDialog, BasePanel, ClientPropertyDetachDialog, PropertyAddDialog, BaseOptionsMenu}})
export default class ClientProperties extends BasePropertyComponent {
  
  @Prop({required: true}) clientResource !: ClientResource
  
  selectedPropertyResource: PropertyResource | null = null
  detachDialog : boolean = false
  attachDialog : boolean = false
  addPropertyDialog : boolean = false
  openBrowse : boolean = false
  sortBy : string = "state.data.lastModifiedDate"
  sortDesc : boolean = true

  headers: any = [
    {
      text: "Address",
      align: "start",
      sortable: true,
      value: "address.address",
      cellClass: "text-truncate"
    },
    {
      text: "Last updated",
      align: "start",
      sortable: true,
      value: "lastModifiedDate",
    },
    {
      text: "Actions",
      align: "start",
      value: "actions",
    },
  ];

  dateRelative(theDate : string) {
    return DateTime.fromISO(theDate).toRelative()
  }

  searchProperty() {
    this.attachDialog = true
  }

  get propertyUtil() : any {
    return PropertyResource
  }

  async editProperty(p: PropertyResource) {
    this.selectedPropertyResource = p.fullResource()
    await this.selectedPropertyResource.get()
    this.addPropertyDialog = true
  }

  detachProperty(p : PropertyResource) {
    this.selectedPropertyResource = p
    this.detachDialog = true
  }

  addProperty() {
    this.selectedPropertyResource = null
    this.addPropertyDialog = true
  }


}
