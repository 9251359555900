
import {Component, Prop} from 'vue-property-decorator'
import { BasePropertyComponent } from '../base/BaseMixins'
import PropertyMap from "@/components/properties/PropertyMap.vue"

@Component({components: {PropertyMap}})
export default class PropertyAddress extends BasePropertyComponent {
  @Prop({default: false}) readonly hideMap !: boolean
  @Prop({default: false}) readonly hideCity !: boolean
  @Prop({default: false}) readonly link !: boolean
  @Prop() readonly textClass !: string
  @Prop() readonly textStyle !: string
  @Prop() readonly iconSize !: string | number
  @Prop({default: false}) readonly showNotes !: boolean
}
