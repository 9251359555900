
import {Component, InjectReactive, Mixins, Prop} from 'vue-property-decorator'
import BaseResourceFormDialog from "@/components/base/BaseResourceFormDialog.vue"
import PropertyMaintenanceSystemForm from "@/components/properties/PropertyMaintenanceSystemForm.vue"
import {ResourceFormDialogMixin} from "@/components/forms/FormsMixin"
import {SettingsResource, PropertyMaintenanceSystemsResource, MaintenanceSystemsResource, PropertyResource} from "@/resources"
import { BasePropertyMaintenanceSystemsComponent } from '../base/BaseMixins'

@Component({components: {BaseResourceFormDialog, PropertyMaintenanceSystemForm}})
export default class PropertyMaintenanceSystemFormDialog extends Mixins(ResourceFormDialogMixin, BasePropertyMaintenanceSystemsComponent) { 

  @InjectReactive() readonly propertyResource!: PropertyResource

  // TODO inject ?
  @Prop() readonly parentPropertyMaintenanceSystemResource!: PropertyMaintenanceSystemsResource
  @Prop() readonly maintenanceSystemResource! : MaintenanceSystemsResource
  @Prop({default:false}) readonly showArchived !: boolean

  newModelFunction() : any {
    return this.rdata
            ? this.rdata
            : {...SettingsResource.defaultObject("propertyMaintenanceSystem"), 
              property: this.propertyResource.resource.uri,
              maintenanceSystem : this.maintenanceSystemResource?.resource.uri,
              parentSystem : this.parentPropertyMaintenanceSystemResource.resource.uri }
  }

  get title() {
    if (this.rdata && this.rdata.maintenanceSystemName) {
      return this.rdata.maintenanceSystemName
    }
    if (this.maintenanceSystemResource) {
      return this.maintenanceSystemResource.data().name.toLowerCase()
    }
    return new MaintenanceSystemsResource().label.toLowerCase()
  }

}
