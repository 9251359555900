
import { Component, Prop } from "vue-property-decorator"
import BasePanel from '@/components/base/BasePanel.vue'
import ContactsList from "../contacts/ContactsList.vue"
import BaseOptionsMenu from "../base/BaseOptionsMenu.vue"
import ContactFormDialog from "../contacts/ContactFormDialog.vue";
import { BaseClientComponent } from "../base/BaseMixins"
import { ContactResource } from "@/resources"
import BaseDeleteAssociationResourceItemDialog from "../base/BaseDeleteAssociationResourceItemDialog.vue"

@Component({components : {BaseDeleteAssociationResourceItemDialog, BaseClientComponent, ContactFormDialog, BasePanel, BaseOptionsMenu, ContactsList}})
export default class ClientContacts extends BaseClientComponent { 
  @Prop({ default : "400px" }) readonly height!: string
  

  contactDialog : boolean = false
  selectedContact !: ContactResource | undefined
  deleteDialog : boolean = false
  activePanel : number = 0

  cid : any = null

  deleteContact(contact : ContactResource) {
    this.selectedContact = contact
    this.deleteDialog = true
  }

  editContact(contact : ContactResource) {
    this.selectedContact = contact
    this.contactDialog = true
  }

  addContact() {
    this.selectedContact = undefined
    this.contactDialog = true
  }
    
}
