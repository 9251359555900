
import {Vue, Component, Prop} from 'vue-property-decorator'
import BaseDialog from "@/components/base/BaseDialog.vue"
import {PropertyResource, ClientResource} from "@/resources"
import AsyncComputed from 'vue-async-computed-decorator' 

@Component({components: {BaseDialog}})
export default class ClientPropertyDetachDialog extends Vue {
  @Prop({ required : true }) readonly propertyResource!: PropertyResource

  detaching : boolean = false
  error : any | null = null

  get clientUtil() {
    return ClientResource
  }

  get propertyUtil() {
    return PropertyResource
  }

  @AsyncComputed()
  async clientResource() {
    return this.propertyResource.client.getAssociation({projection: "clientSummary"})
  }

  detach() {
    this.detaching = true
    this.error = null

    // fetch the client assocition on the property side and delete
    this.propertyResource.client.delete().then(() => {
        this.$emit('input', false)
        this.$emit('update', true)
      })
    .catch((err) => {
        console.error(err)
        this.error = err
    }).finally(() => {
      this.detaching = false
    })
  }

}
