
import { Component, Prop, Watch } from "vue-property-decorator"
import ValueBasedFormsMixin from "@/components/forms/FormsMixin"
import { PropertyResource } from "@/resources"
import { Debounce } from "vue-debounce-decorator"
import { isEqual } from "lodash-es"
import PropertyMap from "../properties/PropertyMap.vue"

@Component({components : {PropertyMap}})
export default class AddressForm extends ValueBasedFormsMixin {

  //@Prop({required: true, default : "address"}) readonly halFormName! : string
  @Prop() readonly geo!: any
  @Prop({default: false }) readonly required!: boolean
  @Prop({default: true }) readonly mailing!: boolean
  @Prop({default: true }) readonly checkAddress!: boolean
  @Prop() readonly label!: string
  
  getHalFormName() : string { return "address" }
  showMap : boolean = false
  searching : boolean = false

  get searchAddress() {
    if (this.value.address && this.value.address.trim() && this.value.city && this.value.city.trim()) {
      return this.value.address + ", " + this.value.city
    }
    return ""
  }

  @Watch('searchAddress')
  @Debounce(1000)
  searchAddressChanged(newVal : any, oldVal : any) {
    if (newVal && !isEqual(newVal, oldVal)) {
      this.geolocateAddress()
    }
  }

  geolocateAddress() {
    if (this.checkAddress && this.searchAddress && !this.searching) {
      this.searching = true
      new PropertyResource("geolocate?address=" + encodeURIComponent(this.searchAddress)).get().then((r) => {
        this.$emit('geoUpdate', r.data)
      }).finally(() => this.searching = false)
    }
  }
  
}
