
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import vueFilePond from "vue-filepond";
import BaseDialog from "../base/BaseDialog.vue";
import {FileStatus} from 'filepond';
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";


// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import Auth from '@/auth'
import {AssociationResource, MediaResource} from '@/resources'

// see https://pqina.nl/filepond/docs/api/plugins/file-validate-size/
// see https://pqina.nl/filepond/docs/api/plugins/file-validate-type/
// see https://pqina.nl/filepond/docs/api/plugins/image-preview/
// see https://pqina.nl/filepond/docs/api/plugins/image-exif-orientation/

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
);

@Component({components : {FilePond, BaseDialog}})
export default class MediaUploadDialog extends Vue {

    @Prop({ required : true }) readonly associationResource!: AssociationResource<MediaResource[]>
    @Prop({ required : true }) readonly url!: string
    @Prop({ required: true}) readonly value!: boolean

    files: any[] = []
    readyForUpload : boolean = false
    processingMedia : boolean = false
    numValidFiles : number = 0

    removeFile() {
      this.numValidFiles = this.getValidFiles().length
    }

    processedFiles() {
      this.readyForUpload = true
      this.processingMedia = false
      this.numValidFiles = this.getValidFiles().length
    }

    processFilesStart() {
      this.readyForUpload = false
      this.processingMedia = true
    }

    close() {
      this.reset();
      this.$emit("input", false)
    }

    reset() {
      this.files.splice(0)
      this.readyForUpload = false;
      this.numValidFiles = 0
    }

    get serverConfig() {
        const headers = {"Authorization" : 'Bearer ' + Auth.getAccessToken()}
        return {
        url: this.url, 
        timeout: process.env.VUE_APP_API_TIMEOUT,
        process: {
            url: '/process',
            method: 'POST',
            withCredentials: true,
            headers : headers
        },
        revert: {
            url: '/revert',
            method: 'DELETE',
            withCredentials: true,
            headers : headers   
        },
        restore: null,
        load: null,
        fetch: null,
        stylePanelLayout : 'circle',
        stylePanelAspectRatio : .75
      }    
    }

    openBrowser() {
      const uploaderElement : any = this.$refs.uploader
      if (uploaderElement) uploaderElement.$el.querySelectorAll('.filepond--browser')[0].click()
    }

    @Watch("value", {immediate: false})
    openBrowse() {
      if (this.value) {
        // note: may not find it first time, else filepond init event
        // catches it, future openings of the dialog will find it.
        this.openBrowser()
      }
    }
    
    getErrorMessage(err : any) {
      return err && err.code == 413 ? "File too large." : "Error during upload."
    }

    getValidFiles() {
        const uploader : any = this.$refs.uploader
        const files : any[] = uploader ? uploader.getFiles() : []
        return files.filter(f => f.status === FileStatus.PROCESSING_COMPLETE)
    }
      
    submit() {
        // only submit files that were processed ok
        const validFiles = this.getValidFiles()
        const idUrls = validFiles.map(f => f.serverId)
        // TODO deal with error
        if (idUrls.length > 0) {

            // reset
            this.reset()
            
            // create promise for accepting uploads
            //var ids = idUrls.map((s) => s.split("/").pop())
            //var acceptResource = new EntityResource(this.url + "accept")
            
            // add associations, then accept uploads
            this.associationResource.addTo(idUrls).then(() => {

              this.$emit("complete", true)
              this.close()

              // accept uploads
              // acceptResource.postNoFollow(ids).then(() => {
              //   this.$emit("complete", true)
              //   this.close()
              // }).catch((e) =>  {
              //   console.error("Failed to accept uploads: ", e)
              //   this.$emit("error", e)
              // })

              
            }, e => {
              console.error("Failed to add media to association: ", e)
              this.$emit("error", e)
            })
        }
    }
}
