
import { Component, Vue, Prop } from "vue-property-decorator"
import { MediaResource } from "@/resources"
import PdfViewerComponent from "@/components/media/PdfViewerComponent.vue"

@Component({ components: {PdfViewerComponent} })
export default class MediaDisplayComponent extends Vue {
  @Prop({ required: true }) readonly media!: MediaResource;
  @Prop({ default: undefined }) readonly width!: MediaResource;

  hasError : boolean = false
}
