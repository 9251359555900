
import { Component, Vue, Prop } from "vue-property-decorator"
import {DateTime} from "luxon"

@Component({components : {}})
export default class BaseDateTimeInput extends Vue {
  @Prop() readonly value!: string
  @Prop({default: false}) readonly showTime!: boolean
  @Prop({default: "Time"}) readonly timeLabel!: string
  @Prop({default: "Date"}) readonly dateLabel!: string
  @Prop({default: "1"}) readonly minuteInterval!: string
  @Prop() readonly prependIcons !: boolean
  @Prop() readonly appendIcons !: boolean

  isScrolled : boolean = false
  dateMenu : boolean = false
  timeMenu : boolean = false

  get dateHint() {
    return this.value ? DateTime.fromISO(this.value).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) : ""
  }

  get dateValue() {
    if (!this.value) {
      return ""
    }
    return DateTime.fromISO(this.value).toISODate()
  }

  set dateValue(val : string) {
    const datetime = DateTime.fromFormat(val + " " + this.timeValue + ":00", "yyyy-MM-dd HH:mm:ss").toISO()
    this.$emit("input", datetime)
  }

  get timeValue() {
    if (!this.value) {
      return "00:00"
    }
    // chop date from time, chop seconds from time
    const theTime = DateTime.fromISO(this.value).toISOTime().split(".")[0]
    return theTime.substring(0, theTime.lastIndexOf(":"))
  }

  set timeValue(val : string) {
    const datetime = DateTime.fromFormat(this.dateValue + " " + val + ":00", "yyyy-MM-dd HH:mm:ss").toISO()
    this.$emit("input", datetime)
  }

  allowedMinuteIntervals(n : number) {
    return n % parseInt(this.minuteInterval) === 0
  }

}
