
import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {AssociationResource} from '@/resources'
import AsyncComputed from 'vue-async-computed-decorator'
import { isEqual } from "lodash-es";

@Component({components: {}})
export default class BaseResourceAssociationDataTable extends Vue {

  @Prop({default: false}) clientSidePagination?: boolean
  @Prop({required: true}) associationResource?: AssociationResource<any[]>
  @Prop({}) associationParams : any
  @Prop({}) uriPatternsToWatch !: any[]

  items : any[] = []
  totalItems = 0
  options: any = { }

  matchesWatchedURIs(uri : string) {
    // try ones passed via prop
    if (!this.uriPatternsToWatch || this.uriPatternsToWatch.length == 0) {
      return false
    }

    for (let i=0; i < this.uriPatternsToWatch.length; i++) {
      if (uri.match(this.uriPatternsToWatch[i])) {
        return true
      }
    }
    return false
   }
  
   // TODO duplicate of BaseMixin
  entityUpdated(event : string) {
    const [type, uri] = event.split(": ")

    if (type && this.matchesWatchedURIs(uri)) {
      this.$asyncComputed.itemsFetch.update()
    }
  }

  beforeMount() { this.$eventBus.on('entityUpdate', this.entityUpdated)}
  beforeUnmount() { this.$eventBus.off('entityUpdate', this.entityUpdated)} // vue3
  beforeDestroy() {  this.$eventBus.off('entityUpdate', this.entityUpdated)} // vue2

  @AsyncComputed()
  async itemsFetch() {
    if (!this.associationResource || this.options.itemsPerPage === undefined) {
      return []
    }

    var linkVariables = this.clientSidePagination 
      ? {...this.associationParams}
      : { ...this.associationParams, size: this.options.itemsPerPage, page: this.options.page - 1 }
    
    this.$emit('updating', true)
    try {
      var entityResources : any[] = await this.associationResource.getAssociation(
        linkVariables,
        false
      )
    }
    finally {
      this.$emit('updating', false)
    }
    this.items = entityResources.map((er : any) => ({...er.data(), resource: er}))

    this.totalItems = this.clientSidePagination 
      ? this.items.length
      : this.associationResource.data().page.totalElements

    this.$emit('count', this.totalItems)
  }

  @Watch("options")
  onOptionsChanged(newVal: any, oldVal: any) {
    if (!this.clientSidePagination && !isEqual(newVal, oldVal)) {
      this.$asyncComputed.itemsFetch.update();
    }
  }

}
