
import {Component} from 'vue-property-decorator'
import MediaListComponent from '@/components/media/MediaListComponent.vue'
import ClientProperties from '@/components/clients/ClientProperties.vue'
import JobsTableClientPaged from '@/components/jobs/JobsTableClientPaged.vue'
import { BaseClientComponent } from '../base/BaseMixins'
import InstantUpdateBoolean from '../forms/InstantUpdateBoolean.vue'
import ClientName from './ClientName.vue'
import { ClientResource } from '@/resources'
import ClientContacts from './ClientContacts.vue'

@Component({metaInfo: {title:process.env.VUE_APP_NAME + " - Client View"}, components: {ClientContacts, MediaListComponent, ClientName, ClientProperties, JobsTableClientPaged, InstantUpdateBoolean}})
export default class ClientDetail extends BaseClientComponent {

  mediaDialogContextLabel : string = ""

  // TODO, way too complicated
  postEntityUpdate() {
    new ClientResource(this.rdata.id + "?projection=clientSummary").get().then((c) => {
      this.mediaDialogContextLabel = "Client file : " + ClientResource.getClientName(c.data)
    })

  }
}
