
import {Component, Mixins, Prop} from 'vue-property-decorator'
import BaseResourceFormDialog from "@/components/base/BaseResourceFormDialog.vue"
import {ResourceFormDialogMixin} from "@/components/forms/FormsMixin"
import {SettingsResource, AssociationResource, ContactResource} from "@/resources"
import { BaseContactComponent } from '../base/BaseMixins'
import ContactForm from './ContactForm.vue'

@Component({components: {BaseResourceFormDialog, ContactForm}})
export default class ContactFormDialog extends Mixins(ResourceFormDialogMixin, BaseContactComponent) { 

  @Prop({}) readonly contactsAssociationResource!: AssociationResource<ContactResource[]>
  @Prop() excludeTypes !: string[]
  @Prop() propertyAddress !: any

  newModelFunction() : any {
    if (this.rdata) {
      return this.rdata
    }
    var data = {...SettingsResource.defaultObject("contact")}
    if (this.propertyAddress) {
      data.address = {...this.propertyAddress}
    }

    return data
  }

  /**
   * Add to contacts if new contact
   * @param data 
   */
  async postSaveCallback(data : any) {
    if (!this.rdata) {
      return this.contactsAssociationResource.addTo(data.uri)
    }
    return Promise.resolve()
  }

  
}
