
import {Vue, Component, Prop} from 'vue-property-decorator'
import BaseDialog from "@/components/base/BaseDialog.vue"
import PropertyAddForm from "@/components/properties/PropertyAddForm.vue"
import {ClientResource, PropertyResource, SettingsResource} from "@/resources"

@Component({components: {BaseDialog, PropertyAddForm}})
export default class PropertyAddDialog extends Vue { 
  @Prop() readonly clientResource!: ClientResource
  @Prop() readonly propertyResource!: PropertyResource

  adding : boolean = false
  isBusiness : boolean = false
  error : any | null = null
  data : any = {}
  valid : boolean = true

  created() {
    if (this.propertyResource) {
      this.$set(this.data, 'property', {...this.propertyResource.data()})
    } else {
      this.$set(this.data, 'property', {...SettingsResource.defaultObject("property"), client: this.clientResource.resource.uri})
    }
  }

  add() {
    const theForm : any = this.$refs.form
    this.valid = theForm.validate()
    if (!this.valid) return

    this.adding = true

    var promise = this.propertyResource ? this.propertyResource.mergePatch(this.data.property) : new PropertyResource().post(this.data.property)
    promise.then(() => {
      this.$emit("update", true)
      this.$emit("input", false)
    }).catch((err) => {
      console.error(err)
      this.error = err
    }).finally(() => {
      this.adding = false
    })
  }

}
