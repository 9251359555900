
import {Component, Prop} from 'vue-property-decorator'
import AddressForm from "@/components/address/AddressForm.vue"
import {ClientResource} from "@/resources"
import ValueBasedFormsMixin from "@/components/forms/FormsMixin"
import ClientName from '../clients/ClientName.vue'
import { cloneDeep } from 'lodash-es'

@Component({components: {AddressForm, ClientName}})
export default class PropertyAddForm extends ValueBasedFormsMixin { 
  @Prop() readonly clientResource!: ClientResource
  @Prop({default: false}) readonly hideWarning!: boolean

  showNotes : boolean = true

  getHalFormName() : string { return "property" }


  updatePropertyType(val : any) {
    const isBiz = (val === "business") ? true : false

    // can't use doUpdate() as we need to set multiple props, then issue the input event
    let clonedValue = cloneDeep(this.getValue())
    clonedValue.isBusiness = isBiz
    if (!isBiz) {
      clonedValue.businessName = null
    }

    this.$emit("input", clonedValue)
  }
}
