
import { Component, Prop } from "vue-property-decorator"
import BasePanel from '@/components/base/BasePanel.vue'
import { ContactResource, CONTACT_TYPES } from "@/resources"
import { BaseContactComponent } from "../base/BaseMixins"
import mailtolink from "mailto-link"
import BaseOptionsMenu from "../base/BaseOptionsMenu.vue"

@Component({components : {BasePanel, BaseOptionsMenu}})
export default class ContactsList extends BaseContactComponent { 
  @Prop({ default : () => []}) readonly types !: CONTACT_TYPES[]
  @Prop({ }) readonly emailSubject !: string
  @Prop({default: false}) hideActions !: boolean
  @Prop({default: false}) noDelete !: boolean
  @Prop() customOption !: string
  @Prop({default: false}) link !: boolean

  get contactUtil() {
    return ContactResource
  }

  get sortedFilteredContacts() {
    // PRIMARY / SECONDARY / BUSINESS / ALTERNATIVE / TENANT / INVOICING
    const contacts = this.filteredContacts
    const sortedContacts = []
    sortedContacts.push(...contacts.filter((c : any) => c.type == 'PRIMARY'))
    sortedContacts.push(...contacts.filter((c : any) => c.type == 'SECONDARY'))
    sortedContacts.push(...contacts.filter((c : any) => c.type == 'BUSINESS'))
    sortedContacts.push(...contacts.filter((c : any) => c.type == 'ALTERNATIVE'))
    sortedContacts.push(...contacts.filter((c : any) => c.type == 'TENANT'))
    sortedContacts.push(...contacts.filter((c : any) => c.type == 'INVOICING'))
    return sortedContacts
  }

  mailtoLink(email : any) {
    return mailtolink({to: email, subject: this.emailSubject})
  }

  get filteredContacts() {
    if (this.types.length) {
      return this.rdata.filter((r:any) => this.types.includes(r.type)).map((r:any) => ({... r, resource: new ContactResource(r.id)}))
    }
    return this.rdata.map((r:any) => ({... r, resource: new ContactResource(r.id)}))
  }

}
