
import {Component, Prop} from 'vue-property-decorator'
import { BasePropertyMaintenanceSystemsComponent } from '../base/BaseMixins'
import PropertyMaintenanceSystemSelector from './PropertyMaintenanceSystemSelector.vue'

@Component({components: {PropertyMaintenanceSystemSelector}})
export default class PropertyMaintenanceSystemInputField extends BasePropertyMaintenanceSystemsComponent {

  @Prop() value !: string | null | undefined

  menu : boolean = false
  
  get inputMessages() {
    return this.selectedSystem && this.selectedSystem.data().location ? this.selectedSystem.data().location : []
  }

  get systemName() {
    return this.selectedSystem ? this.selectedSystem.data().maintenanceSystemName : null
  }

  get selectedSystem() {
    return this.resources.find(r => r.uriFull == this.value)
  }
  set selectedSystem(pms : any) {
    let output = pms && Array.isArray(pms) ? (pms.length > 0 ? pms[0].uriFull : null) : pms.uriFull
    
    if (output != this.value) {
      this.$emit("input", output)
    }
  }
}
