
import { Component, Vue, Prop } from "vue-property-decorator"
import { EntityResource, AssociationResource } from "@/resources"
import BaseYesNoDialog from "@/components/base/BaseYesNoDialog.vue"
import {result} from "lodash-es"

@Component({ components: {BaseYesNoDialog} })
export default class BaseDeleteAssociationResourceItemDialog extends Vue {
  @Prop() readonly itemResource!: EntityResource;
  @Prop() readonly associationResource!: AssociationResource<EntityResource[]>;
  @Prop({required: false, default: "Delete item"}) readonly title!: string;
  @Prop({default: false}) readonly noName!: boolean
  @Prop({default: "resource.uri"}) readonly namePath! : string
  @Prop() readonly name! : string
  @Prop({required: true, default: false}) readonly value!: boolean;
  deleteError : string | null = null
  deleting : boolean = false

  get itemName() {
    return this.name ? this.name : result(this.itemResource.data(), this.namePath)
  }

  doDelete() {
    this.deleting = true
    this.associationResource.deleteFrom(this.itemResource).then(() => {
      this.$emit("delete", this.itemResource)
      this.$emit("input", false)
    }).catch((err) => {
      console.error(err)
      this.deleteError = err
    }).finally(() => {
      this.deleting = false
    })
  }
}
