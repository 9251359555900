
import {Component} from 'vue-property-decorator'
import BaseDialog from "@/components/base/BaseDialog.vue"
import {ClientResource, PropertyResource} from "@/resources"
import PropertySearchField from './PropertySearchField.vue'
import { BaseClientComponent, RequiredProjections } from '../base/BaseMixins'
import ClientName from '../clients/ClientName.vue'

@RequiredProjections("clientNamesOnly")
@Component({components: {BaseDialog, PropertySearchField, ClientName}})
export default class PropertyAttachDialog extends BaseClientComponent { 
  
  propertyResource: PropertyResource | null = null
  propertyClientResource: ClientResource | null = null
  clientResource: ClientResource | null = null
  attachError : string | Error = ""
  attaching : boolean = false

  get clientUtil() {
    return ClientResource
  }

  async setProperty(p : PropertyResource) {
    // fetch client
    this.propertyResource = p
    this.propertyClientResource = null
    this.attachError = ""
    try {
      this.propertyClientResource = await p.client.getAssociation({projection: "clientNamesOnly"})
    }
    catch (e) {
      // ignore 404
    }
  }

  async attach() {
    this.attaching = true

    // detach client from found property if it exists
    if (this.propertyClientResource) {
      try {
        // @ts-ignore
        await this.propertyResource.client.delete()
      }
      catch (e) {
        console.error(e)
        this.attachError = "Could not detach property: " + e
        return
      }
    }

    // attach property to new client
    try {
      // TODO we do this to trigger rel events from both sides... 
      // @ts-ignore
      await this.propertyResource.client.addTo(this.resource?.uriFull)
      // @ts-ignore
      await this.resource.properties.addTo(this.propertyResource?.uriFull)
    }
    catch (e) {
      console.error(e)
      this.attachError = "Could not attach property: " + e
      return
    }

    this.$emit('input', false)
    this.$emit('update', true)
    this.attaching = false
  }

}
