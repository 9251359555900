
import {Component, Prop} from 'vue-property-decorator'
import { BasePropertyMaintenanceSystemsComponent } from '../base/BaseMixins'
import { PropertyMaintenanceSystemsResource } from '@/resources'
import { isEqual } from 'lodash-es'

@Component({components: {}})
export default class PropertyMaintenanceSystemSelector extends BasePropertyMaintenanceSystemsComponent {

  @Prop({required: true}) value !: PropertyMaintenanceSystemsResource | PropertyMaintenanceSystemsResource[]
  
  get active() {
    let values : PropertyMaintenanceSystemsResource[] = Array.isArray(this.value) ? this.value : (this.value ? [this.value] : [])
    return values.map(r => r.uriFull)
  }
  set active(values : string[]) {
    if (!values || values.length == 0) {
      // only emit if changed
      if (this.value || (this.value as any[]).length > 0) {
        this.$emit('input', [])
      }
    }
    let newValues = this.resources.filter(r => values.includes(r.uriFull))

    let oldValueTest : string[]
    if (!this.value) {
      oldValueTest = []
    } else {
      oldValueTest = Array.isArray(this.value) ? this.value.map(r => r.uriFull) : []
    }
    let newValueTest : string[] = newValues.map(r => r.uriFull)

    if (!isEqual(oldValueTest.sort(), newValueTest.sort())) {
      this.$emit('input', newValues)
    }
    
  }

  get tree() {
    // create nodes
    let allSystems : any[] = this.resources.map(r => ({
      name : r.data().maintenanceSystemName,
      location : r.data().location,
      levelType : r.data().levelType,
      id : r.data().id,
      key : r.uriFull,
      resource : r,
      children : []
    }))

    // build children
    this.resources.forEach(r => {
      let thisNode = allSystems.find(node => r.data().id == node.id)
      let parentNode = allSystems.find(node => r.data().parentSystemId == node.id)
      if (parentNode) parentNode.children.push(thisNode)
    })

    // may be no 'root' in which case we will be selecting from a collection of
    // leaf nodes
    let root = allSystems.find(node => !node.resource.data().parentSystemId)
    
    // if no root, show parent folders + location for differentiating same systems
    if (!root) {
      let allSystemsWithParent : any[] = []
      allSystems.forEach(s => {
        allSystemsWithParent.push({
          name : s.resource.data().parentMaintenanceSystemName,
          location : s.resource.data().parentMaintenanceSystemLocation,
          levelType : "SYSTEM", // as long as it's not component
          id : s.resource.data().parentSystemId,
          key : s.resource.data().parentSystemId,  // note, key for parent is not the URI allowing no selection which we want
          disabled: true,
          children : [s]
        })
      })
      return allSystemsWithParent
    }

    
    return root ? root.children : allSystems
  }

}
