
import {Component, Prop, Watch} from 'vue-property-decorator'
import PropertyMaintenanceSystemFormDialog from "@/components/properties/PropertyMaintenanceSystemFormDialog.vue"
import { BaseMaintenanceSystemComponent } from '../base/BaseMixins'
import { MaintenanceSystemsResource, PropertyMaintenanceSystemsResource, PropertyResource } from '@/resources'

/**
 * Allows selecting from the MS tree, firing an MS update event.  If a property is provided and there are PMS corresponding to an MS, the PMS
 * update event will be fired instead.
 * 
 * We don't need to worry about getting an initial value for this field as it will only ever be used in
 * creating a new job context.
 */
@Component({components: {PropertyMaintenanceSystemFormDialog}})
export default class SystemSelectorField extends BaseMaintenanceSystemComponent {

  @Prop() fieldProps !: any
  @Prop({default: null}) propertyURI !: string
  @Prop({default: null}) msURI !: string
  /**
   * Before save, when selecting a new MS from a sibling subtree, this is the 
   * parent pms of that tree (may not be the direct parent which may not be 
   * a PMS).  Without this, we wouldn't know which ms node is the right one
   * to select/keep selected.
   */
  @Prop({default: null}) parentPmsURI !: string

  allPms : PropertyMaintenanceSystemsResource[] = []

  keyIdx : number = 1
  activeItems : any[] = []
  parentMap : any[] = []
  menu : boolean = false
  loadingPMS : boolean = false
  disabled : boolean = false
  textFieldSystem : {name : string | null, location : string | null} = {name : null, location : null}

  @Watch("propertyURI", {immediate: true})
  propertyChanged(newVal : any, oldVal : any) {
    if (newVal != oldVal) {
      this.allPms.splice(0)
      this.loadingPMS = true
      new PropertyResource(this.propertyURI).maintenanceSystems.getAssociation({projection: 'propertyMaintenanceSystemSummary'}, false).then(results => {
        this.allPms.push(...results)
      }).finally(() => this.loadingPMS = false)
    }
  }
  
  get active() {
  //   let values : PropertyMaintenanceSystemsResource[] = Array.isArray(this.value) ? this.value : (this.value ? [this.value] : [])
  //   return values.map(r => r.uriFull)
    return this.activeItems
  }
  set active(values : any[]) {

    this.activeItems = values
    if (values.length > 0) {
      let obj = values[0]
      let uri = obj.uri

      this.textFieldSystem.name = obj.name
      this.textFieldSystem.location = obj.location

      // is this an ms ?
      if (uri.indexOf(MaintenanceSystemsResource.prototype.name) > -1) {
        this.$emit('maintenanceSystem', uri)
        this.$emit('propertyMaintenanceSystem', null)

        // find pms parent (may not be direct parent, and may be none if new)
        let node = this.parentMap[obj.key]
        let parentPms = null
        while (node && !parentPms) {
          if (node.uri.indexOf(PropertyMaintenanceSystemsResource.prototype.name) > -1) {
            parentPms = node
          }
          else {
            node = this.parentMap[node.key]
          }
        }

        if (parentPms) {
          this.$emit('parentOrGrandparentPropertyMaintenanceSystem', parentPms.uri)
        }
        else {
          this.$emit('parentOrGrandparentPropertyMaintenanceSystem', null)
        }

      }
      // or a pms ?
      else if (uri.indexOf(PropertyMaintenanceSystemsResource.prototype.name) > -1) {
        this.$emit('propertyMaintenanceSystem', uri)
        this.$emit('maintenanceSystem', null)
        this.$emit('parentOrGrandparentPropertyMaintenanceSystem', null)
      }
    }
    else {
      // no selection/deselection
      this.textFieldSystem.name = null
      this.textFieldSystem.location = null

      this.$emit('propertyMaintenanceSystem', null)
      this.$emit('maintenanceSystem', null)
      this.$emit('parentOrGrandparentPropertyMaintenanceSystem', null)
    }
  }


  get tree() {
    this.keyIdx = 1
    this.disabled = false
    this.parentMap.splice(0)

    // create nodes for the ms tree
    let msTree : any[] = this.resources.map((r) => ({
      uri : r.uriFull,
      name : r.data().name,
      levelType : r.data().levelType,
      msid : r.data().id,
      disabled : this.msURI && this.msURI != r.uriFull,
      parent_msid : r.data().parentSystemId,
      parent_node : null,
      children : [],
    }))
    // wire up ms children
    msTree.forEach((r:any) => {
      r.key = this.keyIdx++

      let parentNode = msTree.find(node => r.parent_msid == node.msid)
      if (parentNode) {
        this.parentMap[r.key] = parentNode
        parentNode.children.push(r)
      }
    })

    // create pms tree and wire up children
    let pmsTree : any[] = this.allPms.map((r) => ({
      uri : r.uriFull,
      name : r.data().maintenanceSystemName,
      location : r.data().location,
      levelType : r.data().levelType,
      msid : r.data().maintenanceSystemId,
      pmsid : r.data().id,
      parent_pmsid : r.data().parentSystemId,
      parent_node : null,
      children : []
    }))
    // wire up ms children
    pmsTree.forEach((r:any) => {
      r.key = this.keyIdx++

      let parentNode = pmsTree.find(node => r.parent_pmsid == node.pmsid)
      if (parentNode) {
        this.parentMap[r.key] = parentNode
        parentNode.children.push(r)
      }
    })

    // wire in pms, if no pms our ms template is the tree
    let tree = msTree
    if (this.allPms.length > 0) {
      let msRoot = msTree.find(t => t.levelType == "ROOT")
      let pmsRoot = pmsTree.find(t => t.levelType == "ROOT")
      if (!msRoot) { throw new Error("No ROOT of MS tree found !")}
      if (!pmsRoot) { throw new Error("No ROOT of PMS tree found !")}

      let fullTree = this.buildTree(msRoot, pmsRoot);

      // if we are given an msURL, see if it only corresponds to a single
      // MS node in the tree, if true, then we can auto select it and
      // disable the tree
      if (this.msURI) {
        let msCount : any[] = []
        let seeker : Function
        seeker = (node : any) => {
          if (!node.disabled) {
            msCount.push(node)
          }
          node.children.forEach((c:any) => seeker(c))
        }
        seeker(fullTree)

        // if just one, auto select it and
        // disable the control
        if (msCount.length == 1) {
          this.$nextTick(() => {
            this.active = msCount
            this.disabled = true
          })
        }
        // else there is more than one, prune the tree to make
        // it easier
        else {
          let pruner : Function

          pruner = (node : any) => {
            // remove any children that do not have a matching node
            let kids : any[] = [...node.children]
            kids.forEach(c => {
              let found = pruner(c)
              if (!found) {
                var cidx = node.children.findIndex((nc:any) => nc.key === c.key)
                if (cidx != -1) {
                  node.children.splice(cidx, 1)
                }
              }
            })
            // if we found at least one matching child, or this node is
            // matching, we need to keep this node
            var foundOneChild = node.children.length > 0
            return foundOneChild || (!node.disabled)
          }
          pruner(fullTree)

          // ensure everything is deselected when redoing tree
          this.$nextTick(() => {
            this.active = []
          })
        }
      }
      return fullTree.children
    }

    // ensure everything is deselected when redoing tree
    this.$nextTick(() => {
        this.active = []
    })

    // return root
    return tree.find(node => !node.parent_msid).children
  }

  buildTree(msNode : any, pmsNode : any) {
    let newNode : any

    // if no pms node, clone and depth first children
    if (!pmsNode) {
      newNode = {...msNode, children : []}

      // ensure unique key, may be more than
      // on ms node of the same type in the structure
      newNode.key = this.keyIdx++

      msNode.children.forEach((c:any) => {
        let newChild = this.buildTree(c, null)
        this.parentMap[newChild.key] = newNode

        newNode.children.push(newChild)
      })
    }
    // else merge, clone and depth first children
    else {
      newNode = {...msNode, ...pmsNode, children : []}

      // note because we are merging with pms node, we know
      // it will have a unique key

      msNode.children.forEach((mc:any) => {

        // find pms children of same type (may be more than one)
        let pcs = pmsNode.children.filter((pc:any) => mc.msid == pc.msid)
        
        // no kids, clone ms tree
        if (pcs.length == 0) {
          let newChild = this.buildTree(mc, null)
          this.parentMap[newChild.key] = newNode

          newNode.children.push(newChild)
        }
        else {
          pcs.forEach((pc : any) => {
            let newChild = this.buildTree(mc, pc)
            this.parentMap[newChild.key] = newNode

            newNode.children.push(this.buildTree(mc, pc))
          })
        }
      })
    }

    return newNode

  }

}
