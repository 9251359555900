
import { Component, Prop } from "vue-property-decorator"
import ValueBasedFormsMixin from "@/components/forms/FormsMixin"

@Component({components : {}})
export default class PropertyMaintenanceSystemForm extends ValueBasedFormsMixin {
  @Prop({default:false}) readonly showArchived !: boolean

  getHalFormName() : string { return "propertymaintenancesystem" }
}
