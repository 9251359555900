
import { Component, Prop, Watch} from "vue-property-decorator"
import { AssociationResource, HealthReportResource, MediaResource } from "@/resources"
import MediaDisplayComponent from "@/components/media/MediaDisplayComponent.vue"
import InstantUpdateTextField from "@/components/forms/InstantUpdateTextField.vue"
import InstantUpdateTextArea from "@/components/forms/InstantUpdateTextArea.vue"
import printJS from 'print-js' 
import prettyBytes from 'pretty-bytes'
import MediaThumbnailImage from '@/components/media/MediaThumbnailImage.vue'
import BaseDeleteAssociationResourceItemDialog from "@/components/base/BaseDeleteAssociationResourceItemDialog.vue"
import { BaseMediaComponent } from "../base/BaseMixins"
import MediaImageStrip from "@/components/media/MediaImageStrip.vue"

const { DateTime } = require("luxon")

@Component({ components: {MediaImageStrip, MediaDisplayComponent,MediaThumbnailImage, InstantUpdateTextField, InstantUpdateTextArea, BaseDeleteAssociationResourceItemDialog} })
export default class MediaDetailsDialog extends BaseMediaComponent {
  @Prop() readonly managementAssociationResource!: AssociationResource<MediaResource[]>
  @Prop({default: null}) readonly selectedIdx!: number | null;
  @Prop({default: false}) readonly value!: boolean;
  @Prop() readonly customContextLabel!: string;
  
  drawer: boolean = true
  deleting : boolean = false
  selectedMediaIdx : number = 0
  deleteDialog : boolean = false

  get vcardStyle() {
    return this.drawer ? "padding-right:360px !important;" : ""
  }

  healthReportStatusColor(status : string) {
    return HealthReportResource.colorForStatus(status)
  }

  get activeMedia() {
    return this.size > 0 ? this.resources[this.selectedMediaIdx] : null
  }
  set activeMedia(m : MediaResource | null) {
    // dummy setter as we don't push update events and active media can be switched
    // if we have the slideshow.
  }

  // called after association update is triggered
  postEntityUpdate() {
    this.checkBounds();
  }

  @Watch("selectedMediaIdx")
  checkBounds() {
    // upper bound
    if (this.size != 0 && this.selectedMediaIdx > this.size-1) {
      this.selectedMediaIdx = this.size-1
    }
    if (this.size == 0) {
      this.close();
    }
  }

  doDelete() {
    // nuke item so we don't try to display it
    this.resources.splice(this.selectedMediaIdx, 1)
    this.selectedMediaIdx = this.selectedMediaIdx == 0 ? 0 : this.selectedMediaIdx-1
  }


  /**
   * Captures selection changes coming from outside
   */
  @Watch("selectedIdx")
  valueChanged() {
    this.selectedMediaIdx = this.selectedIdx == null ? 0 : this.selectedIdx
  }

  gotoJob(jid : any) {
    this.close()
    this.$router.push({name:'job', params: {jid: jid}})
  }
  gotoSystem(pmsId: any) {
    this.close()
    this.$router.push({name:'propertySystem', params: {pmsId: pmsId}})
  }

  close() {
    //this.$el.scrollTop = 0
    const scrollArea : any = this.$refs.scrollArea
    // may not exist if no media
    if (scrollArea) {
      scrollArea.scrollTop = 0
    }

    this.selectedMediaIdx = 0
      
    // update parent & close
    this.$emit("update")
    this.$emit('input', false)
  }
  
  print() {
    if (this.activeMedia) {
      printJS(this.activeMedia.viewUrl(), this.activeMedia.isPDF() ? undefined : 'image')
    }
  }

  humanReadableFilesize(size : number) {
    return prettyBytes(size)
  }

  formatDate(theDate : string) {
    return DateTime.fromISO(theDate).toLocaleString(DateTime.DATETIME_FULL)
  }

  download() {
    if (!this.activeMedia) {
      return
    }
    window.location.href = this.activeMedia.downloadUrl()
  }
}
