
import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({components: {}})
export default class ContactTypeInfoCard extends Vue {
  @Prop() excludeTypes !: string[]

  hasType(type : string) {
    return !this.excludeTypes || !this.excludeTypes.includes(type)
  }
}
