
import { Component, Prop } from "vue-property-decorator"
import { BaseMediaComponent } from "../base/BaseMixins"

@Component({ components: {} })
export default class MediaThumbnailImage extends BaseMediaComponent {

  @Prop({ default: undefined }) readonly width!: number;
  @Prop({ default: false}) readonly border!: boolean
  @Prop({ default: false}) readonly noTooltip!: boolean
  @Prop() readonly classes!: String

  hasError : boolean = false

}
