
import { Component, Prop } from "vue-property-decorator";
import {AssociationResource, MediaResource} from "@/resources"
import MediaThumbnailImage from '@/components/media/MediaThumbnailImage.vue'
import MediaUploadDialog from "./MediaUploadDialog.vue";
import MediaDetailsDialog from '@/components/media/MediaDetailsDialog.vue'
import BaseDeleteAssociationResourceItemDialog from "@/components/base/BaseDeleteAssociationResourceItemDialog.vue"
import BaseOptionsMenu from '@/components/base/BaseOptionsMenu.vue'
import prettyBytes from 'pretty-bytes'
import BasePanel from '@/components/base/BasePanel.vue'
import { BaseEntityResourceComponent, BaseMediaComponent } from "../base/BaseMixins";

@Component({components : {BasePanel, BaseEntityResourceComponent, MediaThumbnailImage, MediaUploadDialog, MediaDetailsDialog, BaseOptionsMenu, BaseDeleteAssociationResourceItemDialog}})
export default class MediaListComponent extends BaseMediaComponent {
  @Prop() readonly managementAssociationResource!: AssociationResource<MediaResource[]>
  @Prop({ default: process.env.VUE_APP_API_BASE_URL + '/medias/upload' }) readonly uploadUrl!: string
  @Prop({ default : "400px" }) readonly height!: string
  @Prop() readonly customDialogContextLabel !: string
  @Prop({ default : false }) readonly gridView!: boolean
  @Prop({ default: 'Media'}) readonly title !: string
  
  detailsDialog : boolean = false
  deleteDialog : boolean = false
  openBrowse : boolean = false
  listView : boolean = !this.gridView
  uploadDialog : boolean = false
  sortBy : string = "state.data.lastModifiedDate"
  sortDesc : boolean = true
  selectedMedia : MediaResource | null = null

  headers: any = [
    {
      text: "Preview",
      align: "start",
      sortable: false,
      value: "thumbnail",
      width: '100px',
    },
    {
      text: "Name",
      align: "start",
      sortable: true,
      value: "title",
      cellClass: "text-truncate"
    },
    {
      text: "Size",
      align: "start",
      sortable: true,
      value: "size",
      cellClass: "text-no-wrap"
    },
    {
      text: "Actions",
      align: "start",
      value: "actions",
      sortable: false,
    },
  ];

  deleteMedia(item : any) {
    this.selectedMedia = new MediaResource(item.id)
    this.deleteDialog = true
  }

  toggleListView() {
    this.listView = !this.listView
  }

  humanReadableFilesize(size : number) {
    return prettyBytes(size)
  }

  selectMedia(item : any) {
    this.selectedMedia = new MediaResource(item.id)
    this.detailsDialog = true
  }
    
}
