
import { Component, Prop, Watch } from "vue-property-decorator"
import ValueBasedFormsMixin from "@/components/forms/FormsMixin"
import BaseHtmlEditor from "@/components/base/BaseHtmlEditor.vue"
import BaseResourceSelector from "@/components/base/BaseResourceSelector.vue"
import BaseDateTimeInput from "@/components/base/BaseDateTimeInput.vue"
import {ApiResource, AssociationResource, JobTemplateResource, MaintenanceSystemsResource, PropertyMaintenanceSystemsResource, PropertyResource} from "@/resources"
import { cloneDeep, startCase } from "lodash-es"
import RecurrenceControl from "../serviceLoops/RecurrenceControl.vue"
import { LinkVariables } from "ketting/dist/link"
import PropertyMaintenanceSystemInputField from "../properties/PropertyMaintenanceSystemInputField.vue"
import SystemSelectorField from "../properties/SystemSelectorField.vue"

export const FORM_MODE_NEW = 1
export const FORM_MODE_EDIT = 2
export const FORM_MODE_NEW_FROM_JOB = 3
export const FORM_MODE_NEW_FROM_LOOP = 4
export const FORM_MODE_NEW_FROM_SCHEDULER = 5
export const FORM_MODE_NEW_FROM_SCHEDULER_NO_TIME = 6

@Component({components : {SystemSelectorField, BaseHtmlEditor, RecurrenceControl, PropertyMaintenanceSystemInputField, BaseResourceSelector, BaseDateTimeInput}})
export default class JobForm extends ValueBasedFormsMixin {
  
  /**
   * Used for the property address and embedded client, also if the property maintenance system is not provided, used to get all
   * available property maintenance systems for the property.
   */
  @Prop() readonly propertyResource!: PropertyResource

  /**
   * Source for job type selector
   */
  @Prop({required: true}) readonly jobTemplatesAssociation!: AssociationResource<JobTemplateResource[]>

  @Prop({required: true}) mode !: 1 | 2 | 3 | 4 | 5 | 6

  /**
   * Start date might be disabled if there is an appointment attached.
   */
  @Prop({default: false}) hasAppointments !: boolean

  /**
   * For FORM_MODE_EDIT only, where existing type is simple, we allow
   * changing, else no
   */
   @Prop({default: false}) disableJobType !: boolean
  
  getHalFormName() : string { return "maintenancejob" }
  existingJobCheckDismissed = true

  /**
   * for FORM_MODE_NEW only, where JT selection corresponds to multiple
   * PMS with the same MS as the JT.
   */
  hasMultiplePMS : boolean = false
  multiplePMSSearchParams : LinkVariables | null = null
  multiplePropertyMaintenanceSystems : AssociationResource<PropertyMaintenanceSystemsResource[]> = new PropertyMaintenanceSystemsResource().sameMaintenanceSystem
  msURI : string | null = null

  FORM_MODE_NEW = FORM_MODE_NEW
  FORM_MODE_EDIT = FORM_MODE_EDIT
  FORM_MODE_NEW_FROM_JOB = FORM_MODE_NEW_FROM_JOB
  FORM_MODE_NEW_FROM_LOOP = FORM_MODE_NEW_FROM_LOOP
  FORM_MODE_NEW_FROM_SCHEDULER = FORM_MODE_NEW_FROM_SCHEDULER
  FORM_MODE_NEW_FROM_SCHEDULER_NO_TIME = FORM_MODE_NEW_FROM_SCHEDULER_NO_TIME

  indentBase = 2;

  indentForLevelType(type :any) {
    switch(type) {
      case "SYSTEM" : return this.indentBase*0
      case "SUBSYSTEM" : return this.indentBase*1
      case "COMPONENT" : return this.indentBase*2
    }
    return 0
  }

  fromSchedulerPmsUpdate(uri : string) {
    this.doUpdate('propertyMaintenanceSystem', uri); 
    this.$emit('propertyMaintenanceSystem', uri);
  }

  get allMaintenanceSystems() {
    return ApiResource.Instance.maintenanceSystems
  }

  get startDateMessages() {
    return this.hasAppointments ? ['Fixed by appointment'] : []
  }

  /**
   * For the recurrence field, if new and not a single event, the field is mandatory,
   * else if editing we allow clearing it (making an existing job no recurring)
   */
  get recurrenceProps() {
    var props = {...this.halProps('recurrence')}
    if (this.value.id) {
      // if editing existing, we allow changing the schedule or clearing it,
      // so optional, else if new and not a single event, mandatory
      props.label = props.label + " (optional)"
    } 
    else {
      props.required = true
      props.rules.push((v : any) => !!v || startCase(props.label) + ' is required.')
    }
    return props
  }

  /**
   * True if new
   */
  get isNew() {
    return !this.value.id
  }

  /**
   * Watcher for the selected property maintenance system, avoids job recalculation from this.value changes during
   * every form model update.
   * @param newVal 
   * @param oldVal 
   */
  @Watch("value.propertyMaintenanceSystem", {immediate : true})
  pmsChanged(newVal : any, oldVal : any ) {
    if (newVal != oldVal) {
      // this.jobsTemplatesMap = {}
      // this.selectedPropertyMaintenanceSystemUrl = newVal

      // // reset the jobTemplate as this is based on the selected maintenance system
      // if (this.showJobType && this.value.jobTemplate) {
      //   if (!this.avoidJobTemplateUpdate) {
      //     this.doUpdate("jobTemplate", null)
      //   } else {
      //     this.avoidJobTemplateUpdate = false
      //   }
      // }
    }
  }

  /**
   * This will only be done for NEW jobs as PMS and template will be null.  Notifieds the user
   * if any existing open jobs have the same recurrence/template and pms.
   */
  /*@Watch("value.recurrence")
  @Watch("value.jobTemplate")
  @Watch("value.propertyMaintenanceSystem")
  checkForExistingSimilarOpenJobs() {
    if (!!this.value.recurrence && !!this.value.jobTemplate && !!this.value.propertyMaintenanceSystem) {
      const linkVariables = {
        projection: "maintenanceJobSummary", 
        recurrence: this.value.recurrence,
        jobTemplate: this.value.jobTemplate,
        jobId : this.value.id ? this.value.id : -1,
        propertyMaintenanceSystem : this.value.propertyMaintenanceSystem}
      new MaintenanceJobResource().openJobsWithSameRecurrence.getAssociation(linkVariables, false).then((jobs) => {
        if (jobs && jobs.length > 0) {
          const pmsId = jobs[0].data().propertyMaintenanceSystem.id
          const pid = jobs[0].data().property.id
          const jid = jobs[0].data().id
          const routerParams = {name: "job", params:{jid: jid, pid: pid, pmsId: pmsId}}
          this.doUpdate("existingJob", (jobs && jobs.length > 0) ? routerParams : false)
        }
      })
    }
  }*/

  jobTemplateNameProvider(jt : any) {
    return jt.data().maintenanceSystemName + " : " + jt.data().name
  }

  /**
   * Extract service loop job related fields from the selected job.
   * @param uri 
   */
  async updateJobTemplateDetails(uri : any) {

    this.hasMultiplePMS = false

    let job : any = null
    if (uri) {
      job = (await new JobTemplateResource(uri + "?projection=jobTemplateDetail").get()).data
    }

    // if we in a FORM_MODE_NEW_FROM_SCHEDULER context, fetch the ms url as we
    // need this to restrict available systems to choose
    if (this.mode == FORM_MODE_NEW_FROM_SCHEDULER || this.mode == FORM_MODE_NEW_FROM_SCHEDULER_NO_TIME) {
      this.msURI = job ? new MaintenanceSystemsResource(job.systemId).uriFull : null
    }

    // can't use doUpdate() as we need to set multiple props, then issue the input event
    let clonedValue = cloneDeep(this.getValue())
    
    // only change name if not set
    if (!this.value.name) {
      clonedValue.name = job ? job.name : JobTemplateResource.simpleJobLabel
    }
    clonedValue.workflowKey = job ? job.workflowKey : null
    clonedValue.estimatedDuration = job ? job.duration : null
    clonedValue.jobTemplate = job ? uri : null
    clonedValue.jobType = job ? job.name : JobTemplateResource.simpleJobLabel
    clonedValue.maintenanceSystemName = job ? job.maintenanceSystemName : null

    // set the PMS for the job based on the template (only if new, we don't
    // allow changing the PMS after save), we may not have a property so 
    // check for that (in which case a MS selector will be shown)
    if (this.isNew && job && this.propertyResource) {

      // get all PMS with same MS for this property, if none, create on fly, if one use it, else we need user to determine
      // TODO without cache as new PMS won't show up... SSE should be tied to resource
      this.multiplePMSSearchParams = {pid: this.propertyResource.data().id, msid: job.systemId, projection : "propertyMaintenanceSystemSummary"};
      let search = (await this.multiplePropertyMaintenanceSystems.getAssociation(this.multiplePMSSearchParams, false))

      // if none, we need to create on fly, store MSID
      if (search.length == 0) {
        clonedValue.newSystemMid = job.systemId
        clonedValue.propertyMaintenanceSystem = null
      }
      // if one use it
      else if (search.length == 1) {
        clonedValue.newSystemMid = null
        clonedValue.propertyMaintenanceSystem = search[0].uriFull
      }
      // else user needs to choose (leave unset so user has to choose)
      else {
        clonedValue.propertyMaintenanceSystem = null
        clonedValue.newSystemMid = null
        this.hasMultiplePMS = true
      }
    }
    
    this.$emit("input", clonedValue)
  }

  /**
   * Filters out the root property maintenance system 
   * @param pms 
   */
  pmsFilter(pms : any) {
    return pms.data().maintenanceSystemParentId
  }

  /**
   * Adds PMS subsystem URLs to watch in order to update the dropdown should any new PMS be added
   * @param urls 
   */
  pmsSystemURLsToWatch(urls : string[]) {
    if (urls && urls.length) {
      return urls.map(u => u + "/subsystems")
    }
    return []
  }


}
